import {
  Box,
  Container,
  Typography,
  makeStyles,
  IconButton,
} from "@material-ui/core";
import React from "react";
import { FiTwitter } from "react-icons/fi";
import { FaFacebookF } from "react-icons/fa";
import { useHistory } from "react-router-dom";
import FooterLogo from "../../component/FooterLogo";
import { Link as RouterLink } from "react-router-dom";
import { EmailOutlined, LinkedIn } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
    padding: "40px 0px 20px",
    background: "#fff",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    "& .vectorImage": {
      position: "absolute",
      right: "9%",
      top: "5%",
      width: "100%",
      maxWidth: "250px",
    },
    "& .mainBox": {
      position: "relative",
      zIndex: "999",

      "& .IconsBox": {
        padding: "15px 0px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      },
      "& .footerLinkPages": {
        display: "flex",
        // alignItems: "center",
        "& p": {
          fontFamily: "Good Times W00 Bold",
        },
        justifyContent: "space-between",
        position: "relative",
        flexWrap: "wrap",
        paddingBottom: "30px",
        borderBottom: "1px solid #323232",
        "& h4": {
          padding: "15px 0px",
          fontSize: "12px",
          display: "flex",
          alignItems: "center",
          "& span": {
            cursor: "pointer",
            display: "flex",
            width: "max-content",
          },
        },
      },
    },
  },
  logoDrawer2: {
    paddingLeft: "10px",
    width: "300px",
    marginBottom: "20px",
    [theme.breakpoints.down("xs")]: {
      width: "300px",
    },
  },
}));

export default function Footer() {
  const classes = useStyles();
  const history = useHistory();

  return (
    <>
      <Box className={classes.root}>
        <Container maxWidth="lg">
          <Box className="mainBox">
            {/* <Box align="center">
              <RouterLink to="/">
                <FooterLogo className="logoImg" />
              </RouterLink>
            </Box> */}

            {/* ****** Quick Link Container ****** */}
            <Box className="footerLinkPages">
              <Box align="center">
                <RouterLink to="/">
                  {/* <FooterLogo className="logoImg" /> */}
                  <FooterLogo className={classes.logoDrawer2} />
                </RouterLink>
              </Box>

              <Box mx={2} lg={4} style={{ cursor: "pointer" }}>
                <Typography variant="h6" style={{ color: "#000" }}>
                  Quick Links
                </Typography>
                <Typography
                  variant="body1"
                  style={{ color: "#000" }}
                  onClick={() => history.push("/about")}
                >
                  About
                </Typography>
                <Typography
                  variant="body1"
                  style={{ color: "#000" }}
                  onClick={() => history.push("/about")}
                >
                  Contact US
                </Typography>
                <Typography
                  variant="body1"
                  style={{ color: "#000" }}
                  onClick={() => history.push("/about")}
                >
                  Privacy Policy
                </Typography>
                <Typography
                  variant="body1"
                  style={{ color: "#000" }}
                  onClick={() => history.push("/about")}
                >
                  Terms and Conditions
                </Typography>
                <Typography
                  variant="body1"
                  style={{ color: "#000" }}
                  onClick={() => history.push("/about")}
                >
                  FAQs
                </Typography>
              </Box>

              <Box mx={2} lg={4} style={{ cursor: "pointer" }}>
                <Typography variant="h6" style={{ color: "#000" }}>
                  Solutions
                </Typography>
                <Typography
                  variant="body1"
                  style={{ color: "#000" }}
                  onClick={() => history.push("/ComingSoon")}
                >
                  Medical Assistance
                </Typography>
                <Typography
                  variant="body1"
                  style={{ color: "#000" }}
                  onClick={() => history.push("/ComingSoon")}
                >
                  Travel Assistance
                </Typography>
                <Typography
                  variant="body1"
                  style={{ color: "#000" }}
                  onClick={() => history.push("/ComingSoon")}
                >
                  Road Side Assistance
                </Typography>
                <Typography
                  variant="body1"
                  style={{ color: "#000" }}
                  onClick={() => history.push("/ComingSoon")}
                >
                  Mobile Protection
                </Typography>
                <Typography
                  variant="body1"
                  style={{ color: "#000" }}
                  onClick={() => history.push("/ComingSoon")}
                >
                  Warranty Assistance
                </Typography>
              </Box>
            </Box>

            {/* ****** Follow US Container ****** */}
            <Box className="IconsBox">
              <Typography variant="h6" style={{ color: "#000" }}>
                Follow Us On :
              </Typography>
              <IconButton
                target="_blank"
                href="https://www.facebook.com/acrossassist"
              >
                <FaFacebookF style={{ color: "#000" }} />
              </IconButton>
              <IconButton
                target="_blank"
                href="https://www.linkedin.com/company/acrossassist/"
              >
                <LinkedIn style={{ color: "#000" }} />
              </IconButton>

              <IconButton target="_blank" href="https://twitter.com/">
                <FiTwitter style={{ color: "#000" }} />
              </IconButton>
              <IconButton target="_blank" href="mailto:admin@acrossassist.com">
                <EmailOutlined style={{ color: "#000" }} />
              </IconButton>
            </Box>

            {/* ****** Copy Right Container ****** */}
            <Box align="center" mt={2}>
              <Typography
                // variant=""
                style={{
                  color: "#000",
                  fontSize: 16,
                  fontFamily: "sans-serif",
                }}
              >
                Copyright © 2023 Across Assist Pvt Ltd. All rights reserved.
              </Typography>
            </Box>
          </Box>
        </Container>
        <img
          src="images/FooterVector.png"
          alt="Footer Vector Image"
          className="vectorImage"
        />
      </Box>
    </>
  );
}
