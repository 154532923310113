import { createSlice } from "@reduxjs/toolkit";

const calculatorsSlice = createSlice({
  name: "calculators",
  initialState: {
    gst: "",
    idvPreminium: "",
    totalAddOnPremium: "",
    totalGrossPremium: "",
    totalODPremium: "",
    totalPremiumPayable: "",
    totalTPPremium: "",
    netPlan: "",
    totalPlanAmount: "",
  },
  reducers: {
    setCalculatorsDetails: (state, action) => {
      state.gst = action?.payload?.gst;
      state.idvPreminium = action?.payload?.idvPreminium;
      state.totalAddOnPremium = action?.payload?.totalAddOnPremium;
      state.totalGrossPremium = action?.payload?.totalGrossPremium;
      state.totalODPremium = action?.payload?.totalODPremium;
      state.totalPremiumPayable = action?.payload?.totalPremiumPayable;
      state.totalTPPremium = action?.payload?.totalTPPremium;
      state.netPlan = action?.payload?.netPlan;
      state.totalPlanAmount = action?.payload?.totalPlanAmount;
    },
    clearsetCalculatorsDetails: (state) => {
      state.gst = "";
      state.idvPreminium = "";
      state.totalAddOnPremium = "";
      state.totalGrossPremium = "";
      state.totalODPremium = "";
      state.totalPremiumPayable = "";
      state.totalTPPremium = "";
      state.netPlan = "";
      state.totalPlanAmount = "";
    },
  },
});

export const { setCalculatorsDetails, clearsetCalculatorsDetails } =
  calculatorsSlice.actions;
export default calculatorsSlice.reducer;
