import { createSlice } from "@reduxjs/toolkit";

const balanceHistorySlice = createSlice({
  name: "balanceHistory",
  initialState: {
    balanceHistoryList: "",
  },
  reducers: {
    setBalanceHistoryList: (state, action) => {
      // state.balanceHistoryList = action?.payload;
    },
    clearBalanceHistoryList: (state) => {
      state.balanceHistoryList = "";
    },
  },
});

export const { setBalanceHistoryList, clearBalanceHistoryList } =
  balanceHistorySlice.actions;
export default balanceHistorySlice.reducer;
