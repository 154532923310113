import React, { lazy } from "react";
import { Redirect } from "react-router-dom";
import HomeLayout from "src/layouts/HomeLayout";
import DashboardLayout from "src/layouts/DashboardLayout";
import LoginLayout from "./layouts/LoginLayout/inedx";

export const routes = [
  {
    exact: true,
    path: "/",
    layout: HomeLayout,
    // component: lazy(() => import("src/views/pages/Home")),
    component: lazy(() => import("./views/user/pages/Home")),
  },

  {
    exact: true,
    path: "/createPolicy",
    layout: DashboardLayout,
    // component: lazy(() => import("src/views/pages/Profile/EditProfile")),
    component: lazy(() =>
      // import("src/views/user/pages/insurance/createPolicy/createPolicy")
      import("./views/user/pages/insurance/createPolicy/createPolicy")
    ),
  },

  {
    exact: true,
    path: "/viewPolicy",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/user/pages/insurance/viewPolicy/viewPolicy")
    ),
  },
  {
    exact: true,
    path: "/editPolicy",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/user/pages/insurance/editPolicy/editPolicy")
    ),
  },

  {
    exact: true,
    path: "/addBalance",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/user/pages/addBalance/addBalance")),
  },

  {
    exact: true,
    path: "/changePassword",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/user/pages/auth/changepassword")),
  },

  {
    exact: true,
    path: "/dealerMis",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/user/pages/dealerMis/dealerMis")),
  },

  {
    exact: true,
    path: "/previewPolicy",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/user/pages/insurance/previewPolicy/previewPolicy")
    ),
  },

  {
    exact: true,
    path: "/login",
    layout: LoginLayout,
    component: lazy(() => import("src/views/user/pages/auth/login")),
  },

  {
    exact: true,
    path: "/forgotPassword",
    layout: LoginLayout,
    component: lazy(() =>
      import("src/views/user/pages/auth/forgotPassword/forgotPassword")
    ),
  },

  {
    exact: true,
    path: "/otp",
    layout: LoginLayout,
    component: lazy(() => import("src/views/user/pages/auth/otp/otp")),
  },

  {
    exact: true,
    path: "/reset",
    layout: LoginLayout,
    component: lazy(() => import("src/views/user/pages/auth/reset/reset")),
  },

  {
    exact: true,
    path: "/404",
    component: lazy(() => import("src/views/user/errors/NotFound")),
  },

  {
    exact: true,
    path: "/ComingSoon",
    component: lazy(() => import("src/views/user/errors/ComingSoon")),
  },

  {
    component: () => <Redirect to="/404" />,
  },
  {
    component: () => <Redirect to="/ComingSoon" />,
  },
];
