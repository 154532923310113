// import React, { Suspense, useContext, Fragment } from "react";
// import { Router, Switch, Route } from "react-router-dom";
// import { MuiPickersUtilsProvider } from "@material-ui/pickers";
// import MomentUtils from "@date-io/moment";
// import { routes } from "src/routes";
// import { createBrowserHistory } from "history";
// import AuthContext from "src/context/Auth";
// import PageLoading from "src/component/PageLoading";
// import AuthGuard from "src/component/AuthGuard";
// import { ThemeProvider } from "@material-ui/core";
// import { createTheme } from "src/theme";
// import SettingsContext from "src/context/SettingsContext";
// import { ToastContainer } from "react-toastify";

// const history = createBrowserHistory();

// function App() {
//   const themeSeeting = useContext(SettingsContext);
//   const theme = createTheme({
//     theme: themeSeeting.settings.theme,
//   });

//   return (
//     <div className="App">
//       <ThemeProvider theme={theme}>
//         <MuiPickersUtilsProvider utils={MomentUtils}>
//           <AuthContext>
//             <Router history={history}>
//               <RenderRoutes data={routes} />
//               <ToastContainer position="top-right" />
//             </Router>
//           </AuthContext>
//         </MuiPickersUtilsProvider>
//       </ThemeProvider>
//     </div>
//   );
// }

// export default App;

// function RenderRoutes(props) {
//   return (
//     <Suspense fallback={<PageLoading />}>
//       <Switch>
//         {props.data.map((route, i) => {
//           const Component = route.component;
//           const Guard = route.guard ? AuthGuard : Fragment;
//           const Layout = route.layout || Fragment;
//           return (
//             <Route
//               exact={route.exact}
//               key={i}
//               path={route.path}
//               render={(props) => (
//                 <Guard>
//                   <Layout>
//                     {route.routes ? (
//                       <RenderRoutes data={route.routes} />
//                     ) : (
//                       <Component {...props} />
//                     )}
//                   </Layout>
//                 </Guard>
//               )}
//             />
//           );
//         })}
//       </Switch>
//     </Suspense>
//   );
// }

//  ********** New Code 23 July **********
import { Provider } from "react-redux";
import MomentUtils from "@date-io/moment";
import { createBrowserHistory } from "history";
import { ToastContainer } from "react-toastify";
import { ThemeProvider } from "@material-ui/core";
import { Router, Switch, Route } from "react-router-dom";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
// import { PersistGate } from "redux-persist/integration/react";
import React, { Suspense, useContext, Fragment } from "react";

import { routes } from "src/routes";
// import { createTheme } from "src/theme";
import { createTheming } from "src/theme";
import AuthContext from "src/context/Auth";
import AuthGuard from "src/component/AuthGuard";
import { store, persistor } from "./redux/store"; // Import the Redux store
import PageLoading from "src/component/PageLoading";
import SettingsContext from "src/context/SettingsContext";
// import store from "./redux/store";
// import persistor from "./redux/store";
import { PersistGate } from "redux-persist/integration/react";

const history = createBrowserHistory();

function App() {
  const themeSeeting = useContext(SettingsContext);
  const theme = createTheming({
    theme: themeSeeting.settings.theme,
  });

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <div className="App">
          <ThemeProvider theme={theme}>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <AuthContext>
                <Router history={history}>
                  <RenderRoutes data={routes} />
                  <ToastContainer position="top-right" />
                </Router>
              </AuthContext>
            </MuiPickersUtilsProvider>
          </ThemeProvider>
        </div>
      </PersistGate>
    </Provider>
  );
}

export default App;

function RenderRoutes(props) {
  return (
    <Suspense fallback={<PageLoading />}>
      <Switch>
        {props.data.map((route, i) => {
          const Component = route.component;
          const Guard = route.guard ? AuthGuard : Fragment;
          const Layout = route.layout || Fragment;
          return (
            <Route
              exact={route.exact}
              key={i}
              path={route.path}
              render={(props) => (
                <Guard>
                  <Layout>
                    {route.routes ? (
                      <RenderRoutes data={route.routes} />
                    ) : (
                      <Component {...props} />
                    )}
                  </Layout>
                </Guard>
              )}
            />
          );
        })}
      </Switch>
    </Suspense>
  );
}
