import { createSlice } from "@reduxjs/toolkit";

const walletSlice = createSlice({
  name: "wallet",
  initialState: {
    amount: "",
  },
  reducers: {
    setUserBalance: (state, action) => {
      state.amount = action?.payload;
    },
    clearUserBalance: (state) => {
      state.amount = "";
    },
  },
});

export const { setUserBalance, clearUserBalance } = walletSlice.actions;
export default walletSlice.reducer;
