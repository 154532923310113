import React from "react";

const FooterLogo = (props) => {
  return (
    <img
      src={require("../assets/images/Logo.png")}
      alt="Logo"
      {...props}
      height={90}
    />
  );
};

export default FooterLogo;
