import { createSlice } from "@reduxjs/toolkit";

const insuranceSlice = createSlice({
  name: "insurance",
  initialState: {
    makeList: [],
    modelList: [],
    cubicCapacityList: [],
    rtoList: [],
    stateList: [],
    cityList: [],
    zoneList: [],
    calculatorList: {},
    financerStateList: [],
    financerCityList: [],
  },
  reducers: {
    setMakeList: (state, action) => {
      state.makeList = action?.payload;
    },
    setModelList: (state, action) => {
      state.modelList = action?.payload;
    },
    setCubicCapacityList: (state, action) => {
      state.cubicCapacityList = action?.payload;
    },
    setRtoList: (state, action) => {
      state.rtoList = action?.payload;
    },
    setStateList: (state, action) => {
      state.stateList = action?.payload;
    },
    setCityList: (state, action) => {
      state.cityList = action?.payload;
    },
    setZoneList: (state, action) => {
      state.zoneList = action?.payload;
    },
    setCalculatorList: (state, action) => {
      state.calculatorList = action?.payload;
    },
    setFinancerStateList: (state, action) => {
      state.financerStateList = action?.payload;
    },
    setFinancerCityList: (state, action) => {
      state.financerCityList = action?.payload;
    },
    clearInsuranceDetails: (state) => {
      state.makeList = [];
      state.modelList = [];
      state.cubicCapacityList = [];
      state.rtoList = [];
      state.stateList = [];
      state.cityList = [];
      state.zoneList = [];
      state.calculatorList = {};
      state.financerStateList = {};
      state.financerCityList = {};
    },
  },
});

export const {
  setMakeList,
  setModelList,
  setCubicCapacityList,
  setRtoList,
  setStateList,
  setCityList,
  setZoneList,
  setCalculatorList,
  setFinancerStateList,
  setFinancerCityList,
  clearInsuranceDetails,
} = insuranceSlice.actions;
export default insuranceSlice.reducer;
