import React from "react";

const HomeTopBarLogo = (props) => {
  return (
    <img
      src={require("../assets/images/Logo.png")}
      alt="Logo"
      {...props}
      height={40}
    />
  );
};

export default HomeTopBarLogo;
