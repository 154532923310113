import {
  FLUSH,
  PAUSE,
  PURGE,
  PERSIST,
  REGISTER,
  REHYDRATE,
  persistStore,
  persistReducer,
} from "redux-persist";
import { combineReducers } from "redux";
import { configureStore } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import autoMergeLevel1 from "redux-persist/es/stateReconciler/autoMergeLevel1";

import authReducer from "./auth";
import insuranceReducer from "./insurance";
import policyReducer from "./policy";
import savedPolicyReducer from "./savePolicy";
import walletReducer from "./wallet";
import balanceHistoryReducer from "./balanceHistory";
import calculatorsReducer from "./calculators";
import dealerMisReducer from "./dealerMis/dealerMis";
import calculators from "./calculators";

const reducers = combineReducers({
  auth: authReducer,
  insurance: insuranceReducer,
  policy: policyReducer,
  savedPolicy: savedPolicyReducer,
  wallet: walletReducer,
  balanceHistory: balanceHistoryReducer,
  dealerMis: dealerMisReducer,
  calculators: calculatorsReducer,
});

const persistConfig = {
  key: "root",
  storage,
  whitelist: [
    "auth",
    "insurance",
    "policy",
    "savedPolicy",
    "wallet",
    "balanceHistory",
    "calculators",
    "dealerMis",
  ],
  stateReconciler: autoMergeLevel1,
};

const persistedReducer = persistReducer(persistConfig, reducers);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) => {
    const middlewares = getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    });
    // // middlewares.push(logger)
    // if (__DEV__ && !process.env.JEST_WORKER_ID) {
    //   const createDebugger = require('redux-flipper').default;
    //   middlewares.push(createDebugger());
    // }
    return middlewares;
  },
});

const persistor = persistStore(store);

export { store, persistor };
