import { createSlice } from "@reduxjs/toolkit";

const savedPolicySlice = createSlice({
  name: "savedPolicy",
  initialState: {
    savedPolicyId: "",
  },
  reducers: {
    setSavedPolicyId: (state, action) => {
      state.savedPolicyId = action?.payload;
    },
    clearSavedPolicyId: (state) => {
      state.savedPolicyId = "";
    },
  },
});

export const { setSavedPolicyId, clearSavedPolicyId } =
  savedPolicySlice.actions;
export default savedPolicySlice.reducer;
