import { createSlice } from "@reduxjs/toolkit";

const dealerMisSlice = createSlice({
  name: "dealerMis",
  initialState: {
    dealerMisHistory: "",
  },
  reducers: {
    setDealerMisHistory: (state, action) => {
      state.dealerMisHistory = action?.payload;
    },
    clearDealerMisHistory: (state) => {
      state.dealerMisHistory = "";
    },
  },
});

export const { setDealerMisHistory, clearDealerMisHistory } =
  dealerMisSlice.actions;
export default dealerMisSlice.reducer;
