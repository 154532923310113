import { createSlice } from "@reduxjs/toolkit";

const policySlice = createSlice({
  name: "policy",
  initialState: {
    newVehicleValue: "",
    bodyType: "",
    insurer: "",
    registrationType: "",
    coverage: "",
    policyExpire: "",
    transferCase: "",
    riskStartDate: "",
    nilDepValue: "",
    rtiDepValue: "",
    consumableCoverValue: "",
    engineProtectValue: "",
    paToUnnamedPersonValue: "",
    registrationDate: "",
    manufacturingYear: "",
    rto: "",
    make: "",
    model: "",
    cubicCapacity: "",
    engineNumber: "",
    chassisNumber: "",
    registrationNumber: "",
    hypothecatedValue: "",
    financerName: "",
    financerAddress: "",
    tppdCover: "",
    electricalAccessories: "",
    electronicAccessoriesPrice: "",
    nonElectricalAccessories: "",
    nonElectricalAccessoriesPrice: "",
    customerType: "",
    customerName: "",
    companyName: "",
    // gstNumber: "",
    customerGstNumber: "",
    dob: "",
    customerGender: "",
    mobile: "",
    email: "",
    pinCode: "",
    state: "",
    city: "",
    zone: "",
    addressLineOne: "",
    addressLineTwo: "",
    addressLineThree: "",
    kycType: "",
    documentType: "",
    documentNumber: "",
    nomineeName: "",
    nomineeRelation: "",
    nomineeDob: "",
    nomineeAge: "",
    nomineeGender: "",
    exShowRoomPrice: "",
    premium: "",
    premiumGst: "",
    premiumNet: "",
    grossPremium: "",
    gstNumber: "",
    sumInsured: "",

    financerState: "",
    financerCity: "",
    financerName: "",
    discount: "",
  },
  reducers: {
    setPreviewPolicyList: (state, action) => {
      // console.log("66 ==== actions ====", action?.payload);
      state.newVehicleValue = action?.payload?.newVehicleValue;
      state.bodyType = action?.payload?.bodyType;
      state.insurer = action?.payload?.insurer;
      state.registrationType = action?.payload?.registrationType;
      state.coverage = action?.payload?.coverage;
      state.policyExpire = action?.payload?.policyExpire;
      state.transferCase = action?.payload?.transferCase;
      state.riskStartDate = action?.payload?.riskStartDate;
      state.nilDepValue = action?.payload?.nilDepValue;
      state.rtiDepValue = action?.payload?.rtiDepValue;
      state.consumableCoverValue = action?.payload?.consumableCoverValue;
      state.engineProtectValue = action?.payload?.engineProtectValue;
      state.paToUnnamedPersonValue = action?.payload?.paToUnnamedPersonValue;
      state.registrationDate = action?.payload?.registrationDate;
      state.manufacturingYear = action?.payload?.manufacturingYear;
      state.rto = action?.payload?.rto;
      state.make = action?.payload?.make;
      state.model = action?.payload?.model;
      state.cubicCapacity = action?.payload?.cubicCapacity;
      state.engineNumber = action?.payload?.engineNumber;
      state.chassisNumber = action?.payload?.chassisNumber;
      state.registrationNumber = action?.payload?.registrationNumber;
      state.hypothecatedValue = action?.payload?.hypothecatedValue;
      state.financerName = action?.payload?.financerName;
      state.financerAddress = action?.payload?.financerAddress;
      state.tppdCover = action?.payload?.tppdCover;
      state.electricalAccessories = action?.payload?.electricalAccessories;
      state.electronicAccessoriesPrice =
        action?.payload?.electronicAccessoriesPrice;

      state.nonElectricalAccessories =
        action?.payload?.nonElectricalAccessories;
      state.nonElectricalAccessoriesPrice =
        action?.payload?.nonElectricalAccessoriesPrice;
      state.customerType = action?.payload?.customerType;
      state.customerName = action?.payload?.customerName;
      state.prefix = action?.payload?.prefix;
      state.firstName = action?.payload?.firstName;
      state.lastName = action?.payload?.lastName;
      state.companyName = action?.payload?.companyName;
      // state.gstNumber = action?.payload?.gstNumber;
      state.customerGstNumber = action?.payload?.customerGstNumber;
      state.dob = action?.payload?.dob;
      state.customerGender = action?.payload?.customerGender;
      state.mobile = action?.payload?.mobile;
      state.email = action?.payload?.email;
      state.pinCode = action?.payload?.pinCode;
      state.state = action?.payload?.state;
      state.city = action?.payload?.city;
      state.zone = action?.payload?.zone;
      state.addressLineOne = action?.payload?.addressLineOne;
      state.addressLineTwo = action?.payload?.addressLineTwo;
      state.addressLineThree = action?.payload?.addressLineThree;
      state.kycType = action?.payload?.value;
      state.documentType = action?.payload?.documentType;
      state.documentNumber = action?.payload?.documentNumber;
      state.nomineeName = action?.payload?.nomineeName;
      state.nomineeRelation = action?.payload?.nomineeRelation;
      state.nomineeDob = action?.payload?.nomineeDob;
      state.nomineeAge = action?.payload?.nomineeAge;
      state.nomineeGender = action?.payload?.nomineeGender;
      state.exShowRoomPrice = action?.payload?.exShowRoomPrice;
      state.premium = action?.payload?.premium;
      state.premiumGst = action?.payload?.premiumGst;
      state.premiumNet = action?.payload?.premiumNet;
      state.grossPremium = action?.payload?.grossPremium;
      state.gstNumber = action?.payload?.gstNumber;
      state.sumInsured = action?.payload?.suminsurred;
      state.discount = action?.payload?.discount;

      state.financerState = action?.payload?.financerState;
      state.financerCity = action?.payload?.financerCity;
      state.financerName = action?.payload?.financerName;
    },
    clearPreviewPolicyList: (state) => {
      state.newVehicleValue = "";
      state.bodyType = "";
      state.insurer = "";
      state.registrationType = "";
      state.coverage = "";
      state.policyExpire = "";
      state.transferCase = "";
      state.riskStartDate = "";
      state.nilDepValue = "";
      state.rtiDepValue = "";
      state.consumableCoverValue = "";
      state.engineProtectValue = "";
      state.paToUnnamedPersonValue = "";
      state.registrationDate = "";
      state.manufacturingYear = "";
      state.rto = "";
      state.make = "";
      state.model = "";
      state.cubicCapacity = "";
      state.engineNumber = "";
      state.chassisNumber = "";
      state.registrationNumber = "";
      state.hypothecatedValue = "";
      state.financerName = "";
      state.financerAddress = "";
      state.tppdCover = "";
      state.electricalAccessories = "";
      state.electronicAccessoriesPrice = "";
      state.nonElectricalAccessories = "";
      state.nonElectricalAccessoriesPrice = "";
      state.customerType = "";
      state.customerName = "";
      state.companyName = "";
      // state.gstNumber = "";
      state.customerGstNumber = "";
      state.dob = "";
      state.customerGender = "";
      state.mobile = "";
      state.email = "";
      state.pinCode = "";
      state.state = "";
      state.city = "";
      state.zone = "";
      state.addressLineOne = "";
      state.addressLineTwo = "";
      state.addressLineThree = "";
      state.kycType = "";
      state.documentType = "";
      state.documentNumber = "";
      state.nomineeName = "";
      state.nomineeRelation = "";
      state.nomineeDob = "";
      state.nomineeAge = "";
      state.nomineeGender = "";
      state.exShowRoomPrice = "";
      state.premium = "";
      state.premiumGst = "";
      state.premiumNet = "";
      state.grossPremium = "";
      state.gstNumber = "";
      state.sumInsured = "";

      state.financerState = "";
      state.financerCity = "";
      state.financerName = "";
      state.discount = "";
    },
  },
});

export const { setPreviewPolicyList, clearPreviewPolicyList } =
  policySlice.actions;
export default policySlice.reducer;
