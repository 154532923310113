import React from "react";

const Logo = (props) => {
  return (
    <img
      src={require("../assets/images/Logo.png")}
      alt="Logo"
      {...props}
      height={80}
    />
  );
};

export default Logo;
