import {
  Box,
  List,
  Slide,
  Drawer,
  Hidden,
  Button,
  Dialog,
  makeStyles,
  Typography,
  ListSubheader,
  DialogActions,
  DialogContent,
} from "@material-ui/core";
import {
  NoteAddOutlined,
  AddCircleOutlineOutlined,
  FormatListBulletedOutlined,
} from "@material-ui/icons";
import NavItem from "./NavItem";
import PropTypes from "prop-types";
import { User } from "react-feather";
import React, { useEffect, useState } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import { useLocation, matchPath, useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { CLEAR_REDUX_STATE } from "../../../redux/CommonRedux";

const sections = [
  {
    items: [
      {
        title: "Create Policy",
        icon: NoteAddOutlined,
        href: "/createPolicy",
      },
      {
        title: "View Policies",
        icon: FormatListBulletedOutlined,
        href: "/viewPolicy",
      },
      {
        title: "Add Balance",
        icon: AddCircleOutlineOutlined,
        href: "/addBalance",
      },

      {
        title: "Dealer MIS",
        icon: User,
        href: "/dealerMis",
      },
    ],
  },
];

function renderNavItems({ items, pathname, depth = 0 }) {
  return (
    <List disablePadding>
      {items.reduce(
        (acc, item) => reduceChildRoutes({ acc, item, pathname, depth }),
        []
      )}
    </List>
  );
}

function reduceChildRoutes({ acc, pathname, item, depth }) {
  const key = item.title + depth;

  if (item.items) {
    const open = matchPath(pathname, {
      path: item.href,
      exact: false,
    });

    acc.push(
      <NavItem
        depth={depth}
        icon={item.icon}
        info={item.info}
        key={key}
        open={Boolean(open)}
        title={item.title}
      >
        {renderNavItems({
          depth: depth + 1,
          pathname,
          items: item.items,
        })}
      </NavItem>
    );
  } else {
    acc.push(
      <NavItem
        depth={depth}
        href={item.href}
        icon={item.icon}
        info={item.info}
        key={key}
        title={item.title}
      />
    );
  }
  return acc;
}
const useStyles = makeStyles((theme) => ({
  mobileDrawer: {
    width: 256,
    background: "#F5F5F5",
  },
  logoDrawer: {
    width: "230px",
  },
  desktopDrawer: {
    top: "106px",
    width: "245px",
    height: "100% ",
    padding: "10px",
    marginLeft: "17px",
    // margin: "5px 10px 10px 15px",
    background: "#F5F5F5",
    boxShadow: "0px 0px 53px rgba(0, 0, 0, 0.25)",
    borderRadius: "10px",
    marginTop: "0px",
    // marginLeft: "13px",
  },
  avatar: {
    cursor: "pointer",
    width: 64,
    height: 64,
  },
  socialIcon: {
    cursor: "pointer",
    marginRight: 5,
  },
  button: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "left",
    height: "45px",
    paddingLeft: "17px",
    borderRadius: "12px",
    marginTop: "-30px",

    "&:hover": {
      color: "#F5C843",
    },
    "& svg": {
      color: "#F5C843",
      fontSize: "20px",
    },
  },
  btnBox: {
    position: "relative",
    left: "35%",
    bottom: "-250px",
  },
  logoutButton: {
    left: "35px",
    // bottom: "100px",
    bottom: "30px",
    display: "flex",
    position: "absolute",
    fontSize: "17px",
    background: "transparent",
    alignItems: "center",
    fontWeight: "400",
    justifyContent: "start",
    color: "#39aed",
    [theme.breakpoints.down("xs")]: {
      bottom: "-80px",
      // left: "37px",
      left: "57px",
    },
  },
  sideMenuBox: {
    "& .MuiCollapse-wrapperInner": {
      marginLeft: "45px",
    },
  },
}));

const NavBar = ({ onMobileClose, openMobile }) => {
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();

  const [open, setOpen] = useState(false);
  const [isLogout, setIsLogout] = useState(false);
  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location.pathname]);
  const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

  const onLogout = async () => {
    dispatch(CLEAR_REDUX_STATE.CLEAR_LOGIN_DETAILS());
    dispatch(CLEAR_REDUX_STATE.CLEAR_INSURANCE_DETAILS());
    dispatch(CLEAR_REDUX_STATE.CLEAR_PREVIEW_POLICY_LIST());
    dispatch(CLEAR_REDUX_STATE.CLEAR_SAVED_POLICY_ID());
    dispatch(CLEAR_REDUX_STATE.CLEAR_CALCULATOR_DETAILS());
    toast.success("Logout successful!");
    history.push("/login");
  };

  const content = (
    <Box height="100%" display="flex" flexDirection="column">
      <PerfectScrollbar options={{ suppressScrollX: true }}>
        <Box pt={2} pb={2}>
          <Box className="sideMenuBox">
            <Box>
              <img
                alt="Logo"
                className={classes.logoDrawer}
                src={require("../../../assets/images/Logo.png")}
                // height={80}
              />
            </Box>

            {sections.map((section, i) => (
              <List
                key={`menu${i}`}
                subheader={
                  <ListSubheader disableGutters disableSticky>
                    {section.subheader}
                  </ListSubheader>
                }
              >
                {renderNavItems({
                  img: section.img,
                  items: section.items,
                  pathname: location.pathname,
                })}
              </List>
            ))}
          </Box>
        </Box>

        <Button
          onClick={() => setIsLogout(true)}
          className={classes.logoutButton}
        >
          {/* <ExitToAppIcon style={{ color: "black" }} />
          &nbsp; Logout */}
          <ExitToAppIcon
            width="60px"
            height="60px"
            style={{ color: "red", fontSize: "30px" }}
          />
          &nbsp;{" "}
          <Typography variant="body2" style={{ color: "black" }}>
            Logout
          </Typography>
        </Button>

        {isLogout && (
          <Dialog
            maxWidth="sm"
            fullWidth
            className={classes.dailogOpen}
            open={isLogout}
            TransitionComponent={Transition}
            keepMounted
            onClose={() => setIsLogout(false)}
          >
            <Box className="newModalBorder">
              <Box className="mainbox1" style={{ backgroundColor: "white" }}>
                <DialogContent>
                  <Box className={classes.dialougTitle} align="center">
                    <Typography
                      variant="h2"
                      style={{
                        fontFamily: "Sora, sans-serif",
                        fontWeight: "300",
                        marginBottom: "16px",
                        color: "black",
                      }}
                    >
                      Logout
                    </Typography>
                    <Typography
                      variant="body2"
                      style={{
                        fontFamily: "'Sora', sans-serif",
                        color: "black",
                      }}
                    >
                      Are you sure, you want to logout?
                    </Typography>
                  </Box>
                </DialogContent>
                <DialogActions
                  style={{
                    alignContent: "center",
                    justifyContent: "center",
                  }}
                >
                  <Box mt={2} mb={2} maxWidth="400px">
                    {/* <Button
                      variant="contained"
                      color="secondary"
                      className="transparentbutton"
                      style={{ padding: "8px 25px" }}
                      onClick={() => {
                        setIsLogout(false);
                        history.push("/login");
                      }}
                    >
                      Confirm==
                    </Button> */}
                    <Button
                      type="submit"
                      variant="contained"
                      style={{
                        backgroundColor: "#FFAC1C",
                        padding: 12,
                        width: 120,
                        borderRadius: 30,
                        fontFamily: "unset",
                        color: "white",
                      }}
                      onClick={() => onLogout()}
                      // onClick={() => {
                      //   setIsLogout(false);
                      //   toast.success("Logout successful!");
                      //   history.push("/login");
                      // }}
                    >
                      Confirm
                    </Button>
                    &nbsp; &nbsp;
                    {/* <Button
                      className="outlined"
                      style={{ padding: "8px 25px" }}
                      variant="contained"
                      color="secondary"
                      onClick={() => setIsLogout(false)}
                    >
                      Cancel
                    </Button> */}
                    <Button
                      type="submit"
                      variant="contained"
                      style={{
                        backgroundColor: "white",
                        padding: 12,
                        width: 120,
                        borderRadius: 30,
                        fontFamily: "unset",
                        color: "black",
                      }}
                      onClick={() => setIsLogout(false)}
                    >
                      Cancel
                    </Button>
                  </Box>
                </DialogActions>
              </Box>
            </Box>
          </Dialog>
        )}
      </PerfectScrollbar>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          <Box p={2}>{content}</Box>
        </Drawer>
      </Hidden>

      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
};

export default NavBar;
