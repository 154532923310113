import React from "react";
import { Box, Typography } from "@material-ui/core";

const Footer = () => {
  return (
    <Box>
      <Box className="displayCenter" style={{ backgroundColor: "white" }}>
        <Typography
          variant="body2"
          style={{ color: "#000", marginBottom: "5px" }}
        >
          Copyright © 2023{"  "}
          <span className="gradientfirexText" style={{ fontSize: "18px" }}>
            ACROSS ASSIST PVT LTD
          </span>
        </Typography>
      </Box>
    </Box>
  );
};

export default Footer;
